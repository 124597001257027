<template>
  <div class="businessInfo">
    <div class="card">
      <div class="card-body">
        <ValidationObserver v-slot="{ handleSubmit, invalid, touched }" ref="settingsForm">
          <form class="authentication-form" @submit.prevent="handleSubmit(handleSubmitForm)">
            <div class="row pb-4">
              <div class="col-md-4">
                <div class="form-group">
                  <label>Marketing Number</label> <i v-b-tooltip.hover
                    title="This number will be automatically assigned once business is registered."></i> <br />
                  <input v-if="business && business.phone_number" v-model="business.phone_number" type="text"
                    class="form-control" readonly />
                  <span v-else><b class="text-danger">N/A</b></span>
                  <!-- <button v-else type="button" class="btn btn-danger btn-sm" @click="showAreaModal">
                    Assigned number
                  </button> -->
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>Currency</label>
                  <select v-model="business.currency" class="form-control">
                    <option v-for="currency in currencies" :key="currency.name" :value="currency.name">{{ currency.label
                    }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>Date format</label>
                  <select v-model="business.date_format" class="form-control">
                    <option v-for="datetime in dateFormats" :key="datetime" :value="datetime">{{ datetime }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <TextAreaAction v-model="business.help_message" label="Help message" :rows="4" :show-mergefield="true"
                    :sms-length="true" :hide-title="true" :show-redemption="true" :hide-expire-date="true"
                    :hide-upload="true" :hide-contact-dropdown="true" :hide-samcart-dropdown="true"
                    :hide-stripe-dropdown="true" dropdown-size="sm" name="helpMessage" />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <TextAreaAction v-model="business.auto_responder_message" label="Auto responder message" :rows="5"
                    :show-mergefield="true" :sms-length="true" :hide-title="true" :show-redemption="true"
                    :hide-expire-date="true" :hide-upload="true" :hide-contact-dropdown="true"
                    :hide-samcart-dropdown="true" :hide-stripe-dropdown="true" dropdown-size="sm" name="autoResponderMessage" />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <TextAreaAction v-model="business.opt_message" label="Opt out message" :rows="5" :show-mergefield="true"
                    :sms-length="true" :hide-title="true" :show-redemption="true" :hide-expire-date="true"
                    :hide-upload="true" :hide-contact-dropdown="true" :hide-samcart-dropdown="true"
                    :hide-stripe-dropdown="true" dropdown-size="sm" name="optoutMessage" />
                </div>
              </div>
              <div class="col-md-4">
                <SelectInput v-model="business.timezone" :options="timezones" label="Timezone" />
              </div>
              <div class="col-md-4">
                <PhoneInput v-model="user.phone_number" name="Default Business Number" label="Default Business Number"
                  rules="required|phone" :disabled-fetching-country="true" />
              </div>
              <!-- <div class="col-md-4">
                <label class="cursor-pointer">Optin Process <i v-b-tooltip.hover title="Choose how people subscribe to the list. Double opt-in is recommended to help prevent invalid and
                  mailcious contacts from subscribing." class="uil uil-question-circle"></i></label><br />
                <div class="mt-2 pt-2">
                  <b-form-radio v-model="business.double_optin" name="type" :value="true"
                    class="d-block d-flex align-items-center">Double
                    opt-in<br />
                    <small>Require new subscribers to confirm their subscription before being added to the
                      list</small></b-form-radio>
                  <div v-if="business.double_optin" style="padding-left: 40px;" class="mt-3 mb-1">
                    <label class="cursor-pointer">Optin Keyword <i v-b-tooltip.hover
                        title="This Keyword will be used to confirm dobule optin."
                        class="uil uil-question-circle"></i></label><br />
                    <TextInput v-model="business.double_optin_keyword" name="optinKeyword" />
                  </div>
                  <b-form-radio v-model="business.double_optin" name="type" :value="false" class="d-block mt-3">Single
                    opt-in<br /><small>Immediately subscribe people to the list without a
                      confirmation</small></b-form-radio>
                </div>
              </div> -->
            </div>
            <h5 class="h5">Company</h5>
            <div class="row">
              <div class="col-md-4">
                <TextInput v-model="business.name" name="Business Name" label="Business Name" rules="required" />
              </div>
            </div>
            <h5 class="h5 cursor-pointer">Profile </h5>
            <div class="row mt-4">
              <div class="col-md-4">
                <TextInput v-model="user.first_name" name="first_name" rules="required" label="First Name" />
              </div>
              <div class="col-md-4">
                <TextInput v-model="user.last_name" name="last_name" rules="required" label="Last Name" />
              </div>
              <div class="col-md-4">
                <TextInput v-model="user.email" name="email" rules="required|email" label="Email" />
              </div>
            </div>
            <div v-if="showNotificationUsers" class="row mt-4">
              <div class="col-md-8">
                <h5 class="h5 cursor-pointer">New Text Conversation Notifications <i v-b-tooltip.hover
                    title="You will get notified of all new incoming text messages that aren't associated to an automation or when someone subscribes to a keyword. You will also get notified of a new text message after a conversation has been idle for ten minutes or more."
                    class="uil uil-question-circle"></i></h5>
                <table class="table">
                  <thead>
                    <tr>
                      <th>Phone</th>
                      <th>Email</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(notification, index) in business.notifications" :key="`notification-${index}`">
                      <td>
                        <div class="d-flex">
                          <PhoneInput v-model="notification.phone" name="Phone" label=""
                            :rules="notification.active_phone ? 'required|phone' : ''" class="flex-fill mr-4" />
                          <b-form-checkbox v-model="notification.active_phone" class="pt-2" name="check-button" switch
                            inline>
                          </b-form-checkbox>
                        </div>

                      </td>
                      <td>
                        <div class="d-flex">
                          <TextInput v-model="notification.email" name="Email" label=""
                            :rules="notification.active_email ? 'required|email' : ''" class="flex-fill mr-4" />
                          <b-form-checkbox v-model="notification.active_email" name="check-button" class="pt-2" switch
                            inline>
                          </b-form-checkbox>
                        </div>
                      </td>
                      <td>
                        <div class="">
                          <button class="btn btn-danger btn-md" type="button" @click="handleRemoveNotification(index)">
                            <i class="uil uil-trash-alt"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td colspan="2" class="text-right">
                        <button class="btn btn-success btn-md" type="button" @click="handleAddNotification">
                          <i class="uil uil-plus"></i> Add item
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div v-if="user.business.is_template" class="row">
              <div class="col-md-4">
                <UploadImage v-model="user.business.template_image" :business-id="user.business.id"
                  label="Template image" />
              </div>
            </div>
            <b-form-group>
              <b-button variant="primary" :disabled="loadingSave || (invalid && touched)" type="submit">
                <b-spinner v-if="loadingSave" small />
                <span v-else>Save</span>
              </b-button>
            </b-form-group>
          </form>
        </ValidationObserver>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <ValidationObserver v-slot="{ handleSubmit }" ref="settingsForm">
          <form class="authentication-form" @submit.prevent="handleSubmit(updateContactInfo)">
            <h5 class="h5">Contact Information</h5>
            <p>Enter your organization's contact information below. We use this information in the footer of your emails to comply with the CAN-SPAM act.</p>
            <div class="row mt-5">
              <div class="col-md-4">
                <TextInput v-model="contactInfo.name" name="SenderName" label="Default sender name" rules="required" />
              </div>
              <div class="col-md-4">
                <TextInput v-model="contactInfo.email" type="email" name="SenderEmail" label="Default sender email address" rules="required" />
              </div>
              <div class="col-md-4">
                <TextInput v-model="contactInfo.company_name" name="CompanyName" label="Company/Organization name" rules="required" />
              </div>
              <div class="col-md-4">
                <TextInput v-model="contactInfo.website_url" name="WebsiteUrl" label="Website Url" rules="required" />
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <TextInput v-model="contactInfo.address1" name="Address1" rules="required" label="Street address 1" />
              </div>
              <div class="col-md-4">
                <TextInput v-model="contactInfo.address2" name="Address2" label="Street address 2" />
              </div>
              <div class="col-md-4">
                <TextInput v-model="contactInfo.city" name="City" rules="required" label="City" />
              </div>
              <div class="col-md-4">
                <TextInput v-model="contactInfo.state" name="State" rules="required" label="State/Province/Region" />
              </div>
              <div class="col-md-4">
                <ValidationProvider v-slot="{ failed, errors }" name="keyword">
                  <label>Country <span class="text-danger">*</span></label>
                  <multiselect v-model="countrySelected" :allow-empty="false" :options="listCountries" :multiple="false"
                    :class="{ 'is-invalid': failed }" track-by="value" label="label" :show-labels="false"></multiselect>
                  <b-form-invalid-feedback v-if="errors.length">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </ValidationProvider>
              </div>
              <div class="col-md-4">
                <TextInput v-model="contactInfo.zip" name="Zip" rules="required" label="Postal/Zip code" />
              </div>
            </div>
            <b-form-group>
              <b-button variant="primary" :disabled="loadingSave" type="submit">
                <b-spinner v-if="loadingSave" small />
                <span v-else>Save</span>
              </b-button>
            </b-form-group>
          </form>
        </ValidationObserver>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <ValidationObserver v-slot="{ handleSubmit, invalid, touched }" ref="passwordForm">
              <form class="authentication-form" @submit.prevent="handleSubmit(changePassword)">
                <div class="row">
                  <div class="col-md-6">
                    <TextInput v-model="password.password" name="password" rules="required|confirmed:confirm_password"
                      label="Password" type="password" />
                  </div>
                  <div class="col-md-6">
                    <TextInput v-model="password.password_confirmation" vid="confirm_password" name="confirm_password"
                      rules="required" label="Confirm Password" type="password" />
                  </div>
                </div>
                <b-form-group>
                  <b-button variant="primary" :disabled="loadingPassword || (invalid && touched)" type="submit">
                    <b-spinner v-if="loadingPassword" small />
                    <span v-else>Change</span>
                  </b-button>
                </b-form-group>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div v-if="user" class="col-lg-6 col-md-12">
        <div class="card">
          <div class="card-body">
            <ValidationObserver v-slot="{ invalid }" ref="broadcastersForm">
              <div class="row">
                <div class="col-md-12 alert text-left">
                  <span class="d-block">
                    In order to be able to send a broadcast to your subscribers
                    from your mobile phone you will need to add your mobile
                    phone number below.
                  </span>
                  <span class="d-block mt-3">To broadcast from your cell phone simply send the word
                    broadcast followed by your message to your
                    marketing number {{ user.business.phone_number }}.</span>
                  <span class="d-block mt-3">To broadcast to specific keywords simply text the word
                    broadcast (keyword1, keyword2) followed by your message to
                    your marketing number
                    {{ user.business.phone_number }}.</span>
                </div>
              </div>
              <table class="table">
                <thead class="thead-light">
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Phone Number</th>
                    <th class="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(contact, index) in user.business.broadcasters" :key="contact.id">
                    <td>#{{ index + 1 }}</td>
                    <td>
                      <TextInput v-model="contact.name" margin-bottom="0px" />
                    </td>
                    <td>
                      <PhoneInput v-model="contact.phone_number" rules="required|phone" margin-bottom="0px"
                        name="phone" />
                    </td>
                    <td class="text-center">
                      <button class="btn btn-danger btn-sm" @click="removeBroadcaster(index)">
                        <i class="uil uil-trash-alt"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="row">
                <div class="col-md-12 text-right">
                  <button class="btn btn-primary btn" :disabled="invalid || loadingUpdate" @click="addBroadcaster">
                    <i class="uil uil-plus"></i> New Broadcast Number
                  </button>
                  <button class="ml-2 btn btn-primary" :disabled="invalid || loadingUpdate" @click="updateBroadcasters">
                    <b-spinner v-if="loadingUpdate" small />
                    <span v-else><i class="uil uil-upload"></i> Save</span>
                  </button>
                </div>
              </div>
            </ValidationObserver>
          </div>
        </div>
      </div>
      <div v-if="user" class="col-lg-6 col-md-12">
        <div class="card">
          <div class="card-body">
            <h5 class="h5">Message limit</h5>
            <div class="text-center">
              <strong>{{ business.no_messages === null ? 'Unlimited' : `${business.usage_messages} /
                              ${business.no_messages}` }}</strong>
            </div>
            <b-progress :value="business.no_messages !== null ? business.usage_messages : 0" :max="business.no_messages"
              height="25px" class="mb-3" :variant="getLimitColor(business.no_messages, business.usage_messages)"
              animated></b-progress>
            <h5 class="h5 mt-5">Contact limit</h5>
            <div class="text-center">
              <strong>{{ business.no_contacts === null ? 'Unlimited' : `${business.usage_contacts} /
                              ${business.no_contacts}` }}</strong>
            </div>
            <b-progress :value="business.no_contacts !== null ? business.usage_contacts : 0" :max="business.no_contacts"
              height="25px" class="mb-3" :variant="getLimitColor(business.no_contacts, business.usage_contacts)"
              animated></b-progress>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="isShowAreaModal" title="Assign your number">
      <div class="form-group">
        <div class="row">
          <div class="col-9">
            <PhoneInput v-model="searchParams.area_code" name="area_code" label="Area code"
              :disabled-fetching-country="true" @selectedCountry="handleSelectedCountry" />
          </div>
          <div class="col-3">
            <label class="form-control-label"></label>
            <b-button variant="primary" class="d-block" :disabled="!searchParams.area_code || loadingSearch"
              @click="handleSearch">
              <b-spinner v-if="loadingSearch" small />
              <span v-else>Search</span>
            </b-button>
          </div>
        </div>
      </div>
      <div class="row">
        <div v-for="(phone, index) in phones" :key="`phone-${index}`" class="col-6">
          <div class="custom-control custom-radio mb-4">
            <input :id="`phone-${index}`" v-model="selectedPhone" type="radio" name="phone" class="custom-control-input"
              :value="phone.phone_number" />
            <label class="custom-control-label" :for="`phone-${index}`">{{
              phone.phone_number
            }}</label>
          </div>
        </div>
      </div>
      <div v-if="phones !== null && !phones.length">
        <div class="alert alert-danger">
          <span>There are no phone numbers available on Twilio for the area code you are searching for. You can always
            request a phone number directly from Twilio by following this link <a target="_blank"
              href="https://www.twilio.com/console/form/number-request"
              class="text-white text-underline"><u>https://www.twilio.com/console/form/number-request</u></a></span>
        </div>
      </div>
      <template v-slot:modal-footer>
        <b-button variant="light" @click="isShowAreaModal = false">Cancel</b-button>
        <b-button variant="primary" :disabled="!selectedPhone" @click="handleAssign">
          <b-spinner v-if="loadingAssign" small />
          <span v-else>Assign</span>
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="isShowChangeNameModal" title="Warning">
      <p>By clicking submit you are authorizing the URL to redirect from <code>{{ oldUrl }}</code> to
        <code>{{ newUrl }}</code>. After 30 days your <code>{{ oldUrl }}</code> will no longer be available in the system.
      </p>
      <template v-slot:modal-footer>
        <b-button variant="light" @click="onCancelNameChange">Cancel</b-button>
        <b-button variant="primary" @click="updateSettings">Submit</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import Multiselect from 'vue-multiselect'
const { getCodeList } = require('country-list');

export default {
  components: {
    Multiselect
  },

  data() {
    return {
      loadingAssign: false,
      loadingSearch: false,
      isShowAreaModal: false,
      loadingSave: false,
      searchParams: {
        area_code: '',
        country: null,
      },
      phones: null,
      selectedPhone: null,
      business: {},
      contactInfo: {},
      user: {
        business: {},
      },
      loadingUpdate: false,
      loadingSavePage: false,
      notifications: [],
      isShowChangeNameModal: false,
      loadingPassword: false,
      password: {
        password: '',
        password_confirmation: '',
      },
      listCountries: [],
      countrySelected: undefined,
    }
  },

  computed: {
    showNotificationUsers() {
      return this.business.is_enabled_livechat && !this.user.business.is_template
    },
    oldUrl() {
      return this.user && this.user.business && '/' + this.user.business.unique_id
    },

    newUrl() {
      return this.business.name && '/' + this.business.name.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
    },

    countries() {
      return this.$store.getters['app/countryWithCode']
    },

    currencies() {
      return this.$store.getters['app/currencies']
    },

    dateFormats() {
      return this.$store.getters['app/dateFormats']
    },

    timezones() {
      return this.$store.getters['app/timezones']
    },

    authUser() {
      return this.$store.getters['auth/user']
    },

    agency() {
      return this.authUser && this.authUser.business && this.authUser.business.agency
    }
  },

  watch: {
    'authUser.business': function (newVal) {
      if (newVal) {
        this.initData()
      }
    },
    'contactInfo.country': function (val) {
      this.countrySelected = this.listCountries.find(item => item.value === val)  
    }
  },

  mounted() {
    this.initData()
    let countries = getCodeList()
    for (let code of Object.keys(countries)) {
      this.listCountries.push({ value: code, label: countries[code] })
    }
    this.countrySelected = this.listCountries.find(item => item.value === 'us')
  },

  methods: {
    initData() {
      this.user = Vue.util.extend({}, this.authUser)
      this.business = Vue.util.extend({}, this.user.business)
      this.contactInfo = Vue.util.extend({}, this.user.business.contact_info)
    },

    changePassword() {
      this.loadingPassword = true

      this.$store
        .dispatch('user/changePassword', this.password)
        .then(() => {
          this.password = {}
          this.loadingPassword = false
          this.$refs.passwordForm.reset()
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.passwordForm.setErrors(err.response.data.errors)
          }
          this.loadingPassword = false
        })
    },

    getLimitColor(limit, value) {
      if (limit <= value) {
        return 'danger'
      } else if (limit * 0.9 <= value && value < limit) {
        return 'warning'
      }
      return 'primary'
    },
    handleSearch() {
      this.loadingSearch = true

      this.$store
        .dispatch('business/searchNumbers', this.searchParams)
        .then((phones) => {
          this.phones = phones
          this.loadingSearch = false
        })
        .catch(() => {
          this.loadingSearch = false
        })
    },

    handleAssign() {
      this.loadingAssign = true

      this.$store
        .dispatch('business/assignNumber', this.selectedPhone)
        .then(() => {
          this.isShowAreaModal = false
          this.loadingAssign = false
        })
        .catch(() => {
          this.loadingAssign = false
        })
    },

    updateSettings() {
      this.isShowChangeNameModal = false
      this.loadingSave = true

      const requestParam = {
        name: this.business.name,
        address: this.business.address,
        phone_number: this.user.phone_number,
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        email: this.user.email,
        currency: this.business.currency,
        date_format: this.business.date_format,
        auto_responder_message: this.business.auto_responder_message,
        opt_message: this.business.opt_message,
        help_message: this.business.help_message,
        timezone: this.business.timezone,
        notifications: this.business.notifications,
        double_optin: this.business.double_optin,
        double_optin_keyword: this.business.double_optin_keyword,
      }
      this.$store
        .dispatch('business/editSettings', requestParam)
        .then(() => {
          this.loadingSave = false
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.settingsForm.setErrors(err.response.data.errors)
          }
          this.loadingSave = false
        })
    },

    updateContactInfo() {
      this.loadingSave = true

      const requestParam = {
        ...this.contactInfo,
        country: this.countrySelected.value
      }

      this.$store
        .dispatch('business/updateContactInfo', requestParam)
        .then(() => {
          this.loadingSave = false
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.settingsForm.setErrors(err.response.data.errors)
          }
          this.loadingSave = false
        })
    },

    showAreaModal() {
      this.isShowAreaModal = true
    },

    handleSelectedCountry(country) {
      this.searchParams.country = country
    },

    addBroadcaster() {
      this.$refs.broadcastersForm.validate().then((valid) => {
        if (valid) {
          this.user.business.broadcasters.push({
            id: null,
            name: null,
            phone_number: null,
          })
        }
      })
    },

    removeBroadcaster(index) {
      this.user.business.broadcasters.splice(index, 1)
    },

    updateBroadcasters() {
      this.$refs.broadcastersForm.validate().then((valid) => {
        if (valid) {
          this.loadingUpdate = true

          this.$store
            .dispatch('business/updateBroadcasters', {
              broadcasters: this.user.business.broadcasters,
            })
            .then(() => {
              this.loadingUpdate = false
            })
            .catch(() => {
              this.loadingUpdate = false
            })
        }
      })
    },

    handleRemoveNotification(index) {
      this.business.notifications.splice(index, 1)
    },

    handleAddNotification() {
      this.business.notifications.push({})
    },

    handleSubmitForm() {
      if (this.user.business.name !== this.business.name) {
        this.isShowChangeNameModal = true
      } else {
        this.updateSettings()
      }
    },

    onCancelNameChange() {
      this.business = Vue.util.extend({}, this.user.business)
      this.isShowChangeNameModal = false
    }
  },
}
</script>
